// Libs
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import ReactMarkdown from 'react-markdown';

// Components
import { device } from '../device';

import Arrow from 'images/icons/arrow-up.svg';

// Styles
const Container = styled.section`
  padding: ${props =>
    props.isCDICardio ? '4.375rem 0 3.375rem 0' : '4.375rem 0 4.375rem 0'};
  padding: ${props =>
    props.paddingDesktop
      ? props.paddingDesktop
      : props.paddingCentroCirurgico && '4.375rem 0 2.813rem 0'};
  padding: ${props =>
    props.paddingCentroCirurgicoSP && '4.375rem 0 4.375rem 0'};
  background: ${props =>
    props.background
      ? props.background
      : props.isCDICardio
      ? '#ffffff'
      : '#f4f5f7'};
  border-radius: 8px;

  @media ${device.laptop} {
    width: ${props =>
      props.containerWidthLaptop
        ? props.containerWidthLaptop
        : props.isPatologica && '100vw !important'};

    background: ${props => (props.isCDICardio ? '#ffffff' : '#f4f5f7')};
  }

  @media ${device.mobile} {
    border-radius: ${props => props.isPatologica && 0};
  }

  h2 {
    margin-bottom: ${props => (props.infusion ? '1.875rem' : '3.125rem')};
    font-size: 1.5rem;
    font-weight: 400;
    line-height: ${props => props.isPatologica && '1.875rem'};

    @media ${device.laptop} {
      display: ${props =>
        props.isCDICardio || props.infusion || props.isPatologica
          ? 'flex'
          : 'none'};
      margin-top: ${props => props.isCDICardio && '1.875rem'};
      margin-bottom: ${props =>
        props.isCDICardio ? '1.785rem' : props.isPatologica && 0};
      font-size: ${props => props.infusion && '1.125rem'};
      margin: ${props => props.isPatologica && '1.875rem 0 1.875rem 0'};
      line-height: ${props =>
        props.infusion ? '1.375rem' : props.isPatologica && '1.375rem'};
    }

    + p {
      line-height: 30px;
    }

    @media ${device.tablet} {
      font-size: ${props => props.isPatologica && '1.125rem'};
    }

    @media ${device.mobile} {
      margin: ${props => props.isPatologica && '0 0 20px'};
      font-size: ${props => props.isCDICardio && '1.125rem'};
    }
  }

  ul {
    display: grid;
    grid-template-columns: ${props =>
      props.gridTemplateColumnsDesktop
        ? props.gridTemplateColumnsDesktop
        : props.isCDICardio
        ? 'unset'
        : 'repeat(3, 334px)'};
    column-gap: ${props => (props.infusion ? '70px' : '50px')};
    width: 100%;
    margin-bottom: 0;

    @media ${device.laptop} {
      display: ${props =>
        props.isCDICardio || props.atendimentoIsOpen || props.isPatologica
          ? 'grid'
          : props.infusion
          ? 'flex'
          : 'none'};
      grid-template-columns: ${props =>
        props.containerUlGridColumnsLap
          ? props.containerUlGridColumnsLap
          : props.isCDICardio
          ? 'repeat(1, 100%)'
          : 'repeat(2, 49%)'};
    }

    @media ${device.mobile} {
      display: ${props =>
        props.isCDICardio
          ? 'grid'
          : props.atendimentoIsOpen || props.isPatologica || props.infusion
          ? 'flex'
          : 'none'};
      flex-direction: column;
      height: 100%;
      padding: ${props => props.isPatologica && 0};
      transition: transform 0.8s;
    }
  }

  li {
    margin: 0;
    margin-bottom: ${props => props.liMarginBottomDesk || '2.25rem'};
    width: ${props =>
      props.isCDICardio
        ? '27.875rem'
        : props.isPatologica
        ? '21.875rem'
        : '20.875rem'};
    height: ${props =>
      props.isCDICardio || props.isCIGI ? 'unset' : '1.25rem'};
    line-height: 1.625rem;

    @media ${device.laptop} {
      width: ${props => props.isCIGI && '93% !important'};
      margin-bottom: ${props => props.isCDICardio && '1.875rem'};
      font-size: 0.9rem;
      line-height: ${props =>
        props.infusion ? '1.25rem' : props.isCIGI && '1.25rem'};
      :last-child {
        margin-bottom: ${props => props.isPatologica && '30px'};
        height: ${props => props.liLastChildUnsetHeight && 'unset'};
      }
      @media ${device.mobile} {
        :last-child {
          height: 3rem;
        }
      }
    }

    p {
      white-space: initial;
    }

    ::marker {
      color: #4aa8dd;
    }

    @media ${device.laptop} {
      width: auto;
    }

    @media ${device.mobile} {
      width: ${props => props.isCIGI && '93% !important'};
      width: ${props =>
        props.isCDICardio ? '20.875rem' : props.isCIGI && '98% !important'};
      line-height: ${props => props.isCIGI && '1.625rem'};
      :last-child {
        margin-bottom: ${props => props.isPatologica && '0'};
      }
    }

    @media ${device.mobile375} {
      width: ${props => props.isCDICardio && '19.875rem'};
    }

    @media ${device.mobile320} {
      width: ${props => props.isCDICardio && '16.2rem'};
    }
  }

  pre {
    padding: 0;
    background-color: transparent;
  }

  code {
    display: grid;
    grid-row: 1 / 3;
    line-height: 26px;
  }

  @media ${device.laptop} {
    width: ${props => (props.infusion ? '104%' : '100%')};
    padding: 0;
    border-radius: ${props => props.infusion && 'unset'};
  }

  @media ${device.tablet} {
    width: ${props => props.infusion && '106%'};
  }

  @media ${device.mobile} {
    width: ${props => props.infusion && '110%'};
  }

  @media ${device.mobile375} {
    width: ${props => props.infusion && '112%'};
  }

  @media ${device.mobile320} {
    width: ${props => props.infusion && '114%'};
  }
`;

const BoxContent = styled.div`
  width: 100%;
  height: 100%;

  @media ${device.laptop} {
    display: ${props => props.atendimentoIsOpen && 'flex'};

    padding: ${props =>
      props.infusion
        ? '1.875rem 1.25rem 0.625rem 1.25rem'
        : props.isCDICardio
        ? '0'
        : '0 1.25rem 0 1.25rem'};
  }

  @media ${device.mobile} {
    padding: ${props => props.isPatologica && '25px'};

    ${props =>
      props.styleInColumn &&
      css`
        padding: 0;
      `}
  }

  p {
    line-height: ${props => props.infusion && '1.200rem'};
    margin-bottom: ${props => props.infusion && '3.125rem'};

    @media ${device.laptop} {
      font-size: ${props => props.infusion && '0.875rem'};
      line-height: ${props => props.infusion && '1.175rem'};

      :nth-child(2) {
        margin-bottom: ${props => props.infusion && '1.875rem'};
      }
    }

    ${props =>
      props.styleInColumn &&
      css`
        & + p {
          margin-top: 1.625rem;
        }

        & + ul {
          margin-top: 2.25rem;

          @media ${device.laptop} {
            margin-top: 1.875rem;
          }
        }
      `}
  }

  ul {
    @media ${device.laptop} {
      flex-direction: ${props => props.infusion && 'column'};
      margin-left: ${props => props.infusion && '1rem'};
      line-height: ${props => props.infusion && '1.375rem'};
    }

    ${props =>
      props.styleInColumn &&
      css`
        width: unset;
      `}
  }

  li {
    white-space: ${props => props.infusion && 'nowrap'};
    line-height: ${props => props.infusion && '1.375rem'};

    ${props =>
      props.styleInColumn &&
      css`
        width: 100%;
        margin-bottom: 0;

        & + li {
          margin-top: 1.25rem;
        }
      `}
  }
`;
const BoxBottom = styled.div`
  display: none;
  width: 100%;
  border-radius: 8px;

  @media ${device.laptop} {
    display: ${props =>
      props.isCDICardio || props.infusion || props.isPatologica
        ? 'none'
        : 'flex'};
  }
`;
const Button = styled.button`
  display: none;
  width: 100%;
  padding: 1rem 0;
  font-size: 0.875rem;
  color: #45a7df;
  border: none;
  border-radius: 8px;
  background-color: #f4f5f7;
  outline: none;

  @media ${device.laptop} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.782rem 1.25rem;
  }
`;
const ArrowButton = styled.img`
  width: 2rem;
  transform: ${props =>
    props.atendimentoIsOpen ? 'rotate(0)' : 'rotate(180deg)'};
  transition: transform 0.8s;
`;

const MainServices = ({
  markdown,
  isCDICardio,
  isPatologica,
  isCIGI,
  infusion,
  liMarginBottomDesk,
  liLastChildUnsetHeight,
  containerUlGridColumnsLap,
  paddingCentroCirurgico,
  paddingCentroCirurgicoSP,
  gridTemplateColumnsDesktop,
  paddingDesktop,
  styleInColumn,
  containerWidthLaptop,
  background,
}) => {
  const [atendimentoIsOpen, atendimentoSetOpen] = useState(false);
  return (
    <Container
      atendimentoIsOpen={atendimentoIsOpen}
      isCDICardio={isCDICardio}
      isPatologica={isPatologica}
      isCIGI={isCIGI}
      infusion={infusion}
      liMarginBottomDesk={liMarginBottomDesk}
      liLastChildUnsetHeight={liLastChildUnsetHeight}
      containerUlGridColumnsLap={containerUlGridColumnsLap}
      paddingCentroCirurgico={paddingCentroCirurgico}
      paddingCentroCirurgicoSP={paddingCentroCirurgicoSP}
      gridTemplateColumnsDesktop={gridTemplateColumnsDesktop}
      paddingDesktop={paddingDesktop}
      containerWidthLaptop={containerWidthLaptop}
      background={background}
    >
      <BoxBottom
        isCDICardio={isCDICardio}
        isPatologica={isPatologica}
        infusion={infusion}
      >
        <Button
          isPatologica={isPatologica}
          onClick={() => atendimentoSetOpen(!atendimentoIsOpen)}
          atendimentoIsOpen={atendimentoIsOpen}
        >
          Principais atendimentos
          <ArrowButton
            src={Arrow}
            alt="Principais atendimentos"
            atendimentoIsOpen={atendimentoIsOpen}
          />
        </Button>
      </BoxBottom>
      <BoxContent
        isCDICardio={isCDICardio}
        isPatologica={isPatologica}
        atendimentoIsOpen={atendimentoIsOpen}
        infusion={infusion}
        styleInColumn={styleInColumn}
      >
        <ReactMarkdown children={markdown || '-'} />
      </BoxContent>
    </Container>
  );
};

export default MainServices;

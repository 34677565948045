// Libs
import React, { useRef, useEffect } from 'react';

const RenderMaps = ({ scriptReady, defaultCenter, location, zoom, isMap }) => {
  const mapRef = useRef();

  useEffect(() => {
    if (window.google && scriptReady) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: defaultCenter,
        zoom: zoom,
        location: location,
      });

      if (isMap) {
        // eslint-disable-next-line array-callback-return
        location &&
          location.map(item => {
            new window.google.maps.Marker({
              position: item.location,
              title: item.name,
              map: map,
              icon: `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/6mkGmkSgimlLIFO5sWnA`,
            });
          });
      } else {
        new window.google.maps.Marker({
          position: location,
          map: map,
          icon: `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/6mkGmkSgimlLIFO5sWnA`,
        });
      }
    }
  }, [scriptReady]);

  return <div ref={mapRef} css={{ width: '100%', height: '100%' }}></div>;
};

export default RenderMaps;

// Libs
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { globalHistory } from '@reach/router';

function SEO({
  description,
  lang,
  meta,
  title,
  image,
  metaRobots,
  dataSeo,
  titlePosition,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
          }
        }
      }
    `
  );

  const [formattedTitle, setFormattedTitle] = useState('');

  const seoLocalizations = dataSeo
    ? dataSeo[0]?.seo?.localizations?.[0] || dataSeo[0]?.seo
    : [];

  const metaDescription =
    seoLocalizations?.metaDescription ||
    description ||
    site.siteMetadata.description;
  const metaImage = image || site.siteMetadata.image;
  const defaultTitle = seoLocalizations?.metaTitle || site.siteMetadata?.title;

  title = title || site.siteMetadata?.title || '-';

  useEffect(() => {
    const location = globalHistory.location.pathname
      .split('/')
      .slice(-1)[0]
      .replace(/-/g, ' ');
    const formattedLocation = `${
      location[0]?.toUpperCase() ?? ''
    }${location.substring(1)}`;

    const newTitle = `${title} ${
      !!formattedLocation ? `- ${formattedLocation}` : ''
    }`;

    setFormattedTitle(newTitle);
  }, [title]);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={formattedTitle}
      titleTemplate={
        titlePosition === 'left'
          ? defaultTitle
            ? `%s | ${defaultTitle}`
            : null
          : defaultTitle
          ? `${defaultTitle} | %s  `
          : null
      }
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'image',
          content: metaImage,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata?.author || '',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:image',
          content: metaImage,
        },
        {
          name: 'robots',
          content: metaRobots,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: 'pt-br',
  meta: [],
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  dataSeo: PropTypes.arrayOf(PropTypes.object),
};

export default SEO;

// Libs
import React, { useState, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled, { css } from 'styled-components';
import ReactMarkdown from 'react-markdown';

// Components
import Layout from 'components/layout';
import SEO from 'components/seo';
import FeaturedMarketing from 'components/featuredMarketing';
import PromptServiceContent from 'components/promptService/promptServiceContent';
import ListPosts from 'components/blog/listPosts';
import { device } from 'components/device';
import Accordion from 'components/Accordion';
import MainServices from 'components/promptService/mainServices';

// Styles
export const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '0 20px'};

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    padding-top: 0;
    display: ${props => props.isDesktop && 'flex'};
    padding: 0 20px;
  }

  .cardsCdi {
    section {
      width: 100%;
      div {
        width: 100%;
        margin-left: 0;
        @media ${device.laptop} {
          display: flex;
        }
        div {
          > ul {
            width: 100%;
            @media ${device.laptop} {
              display: flex;
              flex-direction: column;
              margin: 0;
            }
            > li {
              width: 100%;
              margin-right: 0;
              > h2 {
                font-weight: 400;
                width: 65%;
                padding-bottom: 1.875rem;

                @media ${device.laptop} {
                  width: 100%;
                  margin: 0;
                  padding: 0;
                  padding-bottom: 1.875rem;
                }
              }
              :hover {
                box-shadow: unset;
              }
              :first-child {
                margin-right: 0;
                padding: 0 15px 0 0 !important;
                @media ${device.laptop} {
                  height: unset;
                  padding: 0 !important;
                  border-bottom: 1px solid #c6c8cc;
                  border-radius: unset;
                }
              }
              :last-child {
                margin-right: 0;
                padding: 0 0 0 15px !important;
                @media ${device.laptop} {
                  height: unset;
                  padding: 0 !important;
                }
              }
              @media ${device.laptop} {
                height: unset;
              }
              ul {
                grid-template-columns: 1fr;
                width: 100%;
                li {
                  margin-right: 0;
                  @media ${device.laptop} {
                    width: 100%;
                    height: unset;
                  }
                  p a {
                    display: contents;
                  }
                  h3 {
                    width: 100%;
                    > a {
                      :first-child {
                        @media ${device.laptop} {
                          display: flex;
                          width: 100%;
                        }
                        @media ${device.tablet} {
                          width: 100%;
                        }
                      }
                      :last-child {
                        @media ${device.laptop} {
                          top: 0;
                          right: 0;
                        }
                      }
                    }
                    @media ${device.laptop} {
                      width: 100%;
                      position: relative;
                    }
                  }
                }
                @media ${device.laptop} {
                  display: flex;
                  flex-direction: column;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const GridMainServices = styled.div`
  margin: 0;
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;
  background: #f4f5f7;
  overflow-x: hidden;

  @media ${device.laptop} {
    width: 100vw;
    margin: 0 -20px;
    display: flex;
    flex-direction: column-reverse;
    column-gap: 10px;
  }
`;

export const Part = styled.div`
  grid-column: ${props => props.gridColumn};

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIUnits ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
  }

  ${props =>
    props.padding &&
    css`
      padding: 4.375rem 0;

      @media ${device.laptop} {
        padding: 1.875rem 0;
      }
    `}
`;

export const AccordionContainer = styled.div`
  margin-bottom: 4.375rem;

  @media ${device.laptop} {
    margin-bottom: 1.875rem;
  }
`;

export const AccordionContainerTitle = styled(ReactMarkdown)`
  p,
  h2 {
    font-size: 2.25rem;
    font-weight: 600;
    text-align: center;
    margin: 4.375rem 0;

    @media ${device.laptop} {
      font-size: 1.625rem;
      margin: 1.875rem 0;
    }
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderPage = (page, isDesktop, pageContext) => {
  return (
    <GridContainer isDesktop={isDesktop}>
      <Part gridColumn={'2 / span 12'} isCDIUnits>
        <FeaturedMarketing
          markdown={getMarkdown(page, 'cktvfj8mg8ym50b74z6y5uprt', true)}
          breadCrumb={getMarkdown(page, 'cktvfhv8o8ydu0c720bpjroz2', true)}
          asset={getMarkdown(page, 'cktvfj8mg8ym50b74z6y5uprt', false, true)}
          isOncologia
          boxContentHeightDesk="42rem"
          boxInfoHeightLap="32rem"
          containerNoBorderBottomLap
          containerMarginBottomTab="1.875rem"
          containerMarginBottomMob="0"
          boxInfoPaddingRightLap
          boxInfoTextWidthDesk="29.125rem"
          boxInfoPaddingLeftEmDesk="2rem"
          boxInfoParagraphImgPaddingRMobile320="0.5rem"
          boxInfoPaddingRImgDesk2="0.425rem"
          boxInfoParagraphImgWidth="unset"
          boxInfoParagraphImgHeight="unset"
          boxInfoTagPColor="#231f20"
          boxInfoImgChild5PaddingRight="0.3rem"
          boxInfoImgChild5Transform="translate(0, 85%)"
          boxInfoImgChild4Transform="translate(0, 80%)"
          boxInfoImgChild7PaddingRight="0.5rem"
          boxInfoImgChild7Transform="translate(0, 25%)"
          boxInfoImgChild8PaddingRight="0.3rem"
          boxInfoImgChild8Transform="translate(0, 25%)"
          boxInfoPFontWeightNormal
          boxInfoTagEmDisplay="block"
          boxContentFlexDirectionLap="column-reverse"
          boxContentFlexDirectionTab="column-reverse"
          widthImgMarketingLap="25rem"
          widthImgMarketingTab="17rem"
          overflowContainerMobile="hidden"
          marginTopBoxInfoLap="0"
          marginTopBoxInfoTab="-4rem"
          boxInfoParagraphImgTransformDesk6="translate(0, 30%)"
          boxInfoPaddingRImgDesk6="0.425rem"
          boxInfoImgChild7Transform="translate(0, 30%)"
          boxInfoPaddingLeftEmDesk="2.1rem"
          boxInfoPaddingLeftEmDesk="2rem"
          boxInfoPaddingLeftEmDesk2="16px"
          boxInfoParagraphImgPaddingRMobile320="0.5rem"
          boxInfoParagraphImgLastTransformDesk="translate(0,80%)"
          boxInfoParagraphImgWidth="unset"
          boxInfoParagraphImgHeight="unset"
          marginLeftStrong={'0.4rem'}
          boxImagesJustifyContentLap={'center'}
        />
      </Part>
      <Part gridColumn={'2 / -2'} borderBottom notPadding notLeft>
        <PromptServiceContent
          markdown={getMarkdown(page, 'cktvfzp7s9bgu0b74r3bl9328', true)}
          fragments={getMarkdown(page, 'cktvfzp7s9bgu0b74r3bl9328')}
          padding
          notTitleBold
          facilitiesForYou
          isBottom
          prontoAtendimento
          isFacilities
          isOncologia
          isBorder
        />
      </Part>
      <Part gridColumn={'2 / -2'} borderBottom notPadding notLeft>
        <AccordionContainer>
          <AccordionContainerTitle children={'Principais Tratamentos'} />
          <div>
            <Accordion
              markdownContent={getMarkdown(
                page,
                'cktvggl8o9pm70c72hx7rx831',
                true
              )}
            />
            <Accordion
              markdownContent={getMarkdown(
                page,
                'cktvgiioo9r470b74tkkbwi27',
                true
              )}
            />
          </div>
        </AccordionContainer>
      </Part>
      <Part
        gridColumn={'2 / -2'}
        largeTitle
        notPadding
        notLeft
        shortPadding
        borderBottom
      >
        <PromptServiceContent
          markdown={getMarkdown(page, 'cktvg22v49h1v0b76ws09jjmd', true)}
          fragments={getMarkdown(page, 'cktvg22v49h1v0b76ws09jjmd')}
          padding
          notTitleBold
          facilitiesForYou
          isBottom
          prontoAtendimento
          isFacilities
          isPatologica
          withoutBorderTop
        />
        <PromptServiceContent
          markdown={getMarkdown(page, 'cktvg8oio9igi0c72dgw4prai', true)}
          fragments={getMarkdown(page, 'cktvg8oio9igi0c72dgw4prai')}
          breakLineBr
          padding
          notTitleBold
          facilitiesForYou
          isDropdownDesktop
          isPatologica
          containerPaddingDesk=".125rem 0 4.375rem"
          withoutBorderTop
        />
      </Part>
      <GridMainServices>
        <Part gridColumn={'2 / -2'}>
          <MainServices // Areas de experiencia
            markdown={getMarkdown(page, 'cktvgx9jsa25o0b76dburyojt', true)}
            fragments={getMarkdown(page, 'cktvgx9jsa25o0b76dburyojt')}
            liMarginBottomDesk="1rem"
            isPatologica
            isCIGI
          />
        </Part>
      </GridMainServices>
      {page?.[0]?.posts?.length > 0 && (
        <Part gridColumn={'2 / -2'} padding>
          <ListPosts
            title="Blog do Sírio-Libanês"
            postsToShow={3}
            posts={page?.[0]?.posts}
            headerLink={`/${page[0]?.customURLBlog ?? 'blog/'}`}
          />
        </Part>
      )}
    </GridContainer>
  );
};

const HematologiaPediatrica = ({ page, pageContext }) => {
  const [isDesktop, setDesktop] = useState(true);
  const [, setRedirect] = useState(false);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
    setRedirect(true);
  });

  const getImage = getMarkdown(page, 'cktvfj8mg8ym50b74z6y5uprt', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {renderPage(page, isDesktop, pageContext)}
    </Layout>
  );
};

export default props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "cktvgoy609vco0b74pplwvcqf" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
                customURLBlog
                posts {
                  id
                  slugPost
                  title
                  topic
                  author
                  doctors(first: 1000, locales: [pt_BR, en]) {
                    id
                    id_api
                    active
                    assignment
                    name
                  }
                  assetpicker {
                    handle
                    height
                    width
                  }
                  categories {
                    tag
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <HematologiaPediatrica page={response.gcms.site.pages} {...props} />
        );
      }}
    />
  );
};
